import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import Headline from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Headline"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Button from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Button"
import { Link } from "gatsby"

const VideoBgHeroBlock = ({ block, breadcrumbs, forceLoadImages = false }) => {
  return (
    <>
      {block.video?.filename?.length > 0 && (
        <BlockWrapper
          block={block}
          blockPadding=""
          blockWidth="screen"
          showHeadline="false"
        >
          <div
            className={`relative mx-auto bg-black h-auto ${
              block.reduced_height ? "md:h-[60vh]" : "md:h-vh-75"
            }`}
          >
            <video
              className={`w-full h-auto ${
                block.reduced_height ? "md:h-[60vh]" : "md:h-vh-75"
              } object-cover object-center`}
              autoPlay={true}
              muted={true}
              loop={true}
              src={block.video?.filename}
              disablePictureInPicture={true}
            />
            {(breadcrumbs?.length > 0 ||
              block.headline?.length > 0 ||
              block.text?.length > 0 ||
              block.buttons?.length > 0) && (
              <div className="bg-black md:bg-transparent md:inset-0 md:absolute">
                <div className="h-full max-w-screen-xl px-4 py-8 mx-auto">
                  <div className="flex flex-col justify-between h-full md:w-1/2">
                    <div>
                      <div className="block mb-2 -ml-2 text-sm">
                        {breadcrumbs?.map((breadcrumb, index) => (
                          <span key={breadcrumb.link}>
                            {index > 0 && (
                              <span className="inline-block h-4 text-white">
                                /
                              </span>
                            )}
                            <Link
                              className={`px-2 hover:underline text-white`}
                              key={index}
                              to={breadcrumb.link}
                            >
                              {breadcrumb.link_text}
                            </Link>
                          </span>
                        ))}
                      </div>
                      {block.headline?.length > 0 && (
                        <Headline
                          className="text-white drop-shadow-lg"
                          headlineLevel="h1"
                          headline={block.headline}
                          alternativeFont={block.alternative_font}
                        />
                      )}
                    </div>
                    <div>
                      {block.text?.length > 0 && (
                        <Richtext
                          className="text-white drop-shadow-lg"
                          text={block.text}
                        />
                      )}
                      {block.buttons?.length > 0 && (
                        <div className="mt-8 -mb-4">
                          {block.buttons?.map((button, index) => (
                            <Button
                              key={index}
                              className={`mb-4 ${
                                index + 1 < block.buttons.length ? "mr-4" : ""
                              }`}
                              btnType={
                                index + 1 === block.buttons.length
                                  ? "primary hover:border-white"
                                  : "tertiary"
                              }
                              link={button.link}
                              target={button.target}
                              externalIcon={button.external_icon}
                              icon={button.icon}
                            >
                              {button.link_text}
                            </Button>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </BlockWrapper>
      )}
    </>
  )
}

export default VideoBgHeroBlock
